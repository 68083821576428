import React from "react";
import { graphql } from "gatsby";
import ProductTemplate from "../../templates/product-template";
import Seo from "../../components/SEO";
import Layout from "../../components/Layout";

const Product = ({ data }) => {
  const { contentfulProduct } = data;

  // Conditionally build the GraphQL query based on the presence of embedded data
  const query = `
    query {
      contentfulProduct(id: { eq: "${contentfulProduct.id}" }) {
        title
        introduction
        price
        description {
          raw
          ${
            contentfulProduct.description.raw.includes("embedded-asset-block")
              ? `
              references {
                ... on ContentfulAsset {
                  __typename
                  contentful_id
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
              `
              : ""
          }
        }
        faqs {
          question
          answer {
            raw
          }
        }
        gallery {
          gatsbyImageData(
            width: 1000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `;

  return (
    <>
      <Seo title={contentfulProduct.title} />
      <Layout>
        <ProductTemplate {...contentfulProduct} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query ProductQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      description {
        raw
      }
      faqs {
          question
          answer {
            raw
          }
        }
        gallery {
          gatsbyImageData(
            width: 1000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            )
            }
    }
  }
  
`;

export default Product;
